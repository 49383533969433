<style lang="scss">

.v-card--reveal-top {
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.upload {
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding-bottom: 3px;
  position: absolute;

}

.shade {
  background-color: rgba(0, 0, 0, 0.5);
}
.white-shade {
  background-color: rgba(255, 255, 255, 0.1);
}

.small_notification {
  font-size: 10px;
}

.small-list {
  max-height: 300px;
  overflow-y: auto;
}
.small-list  .v-list-item--dense.v-list-item--two-line {
  min-height: 33px !important;
}
.small-list .v-list-item--dense .v-list-item__content {
  padding: 1px 0 !important;
}
.small-list .v-list-item__content>:not(:last-child) {
  margin-bottom: 0px !important;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(170, 170, 170, 0.99) transparent;
}

@include media-breakpoint-up(md) {
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(170, 170, 170, 0.99);
  border-radius: 20px;
  border: 3px solid transparent;
}
.v-application.theme--dark
{
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(170, 170, 170, 0.99) transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(170, 170, 170, 0.99);
    border-radius: 20px;
    border: 3px solid transparent;
  }
}
.hover:hover {
  background-color:  rgba(170, 170, 170, 0.99);
  border-radius: 5px;
}

.slick-secondary .slick-track {
  display: flex !important;
  gap: 12px;
}
.slick-track {
  display: flex !important;
}
.quantity input {
  text-align: center !important;
}
.gap-2 {
  gap: 1rem;
}
.image-list-container .image-list-item {
  height: 60px !important;
  width: 100px !important;

}
.show-img {
  max-height: 50px !important;
  max-width: 90px !important;
  display: block;
  vertical-align: middle;
}
.image-container {
  width: 330px !important;
  height: 320px !important;
  margin: auto !important;
}
.preview-image {
  height: 280px !important;
}
.image-container .show-img {
  max-width: 290px !important;
  max-height: 280px !important;
}
.image-list-container {
  max-width: unset !important;
}
.cursor-grap {
  cursor: grab;
}
.cursor-move {
  cursor: move;
}


/* Remove autofill styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent !important;
  color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}

/* Hide browser autofill icon */
input[data-com-one-time-key] {
  display: none !important;
}
.c-BlockPadding {
  display: none !important;
}
</style>
<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import router from './router'
  import Vue from 'vue'
  import { getToken, onMessage } from 'firebase/messaging'
  export default {
    name: 'App',
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Planet-Market',
      // all titles will be injected into this template
      titleTemplate: '%s'
    },
    components: { },

    data: () => ({
      not_clicked: true,
      dialog: false,
      menu_bar: null,
      drawer: null,
      mini: true,
      windowWidth: 0,
      windowHeight: 0,
      search: '',
      items: [],
      languages: [
        { value: 'en', title: 'English', icon: 'https://cdn.staticaly.com/misc/flags/us.svg' },
        { value: 'fr', title: 'French', icon: 'https://cdn.staticaly.com/misc/flags/fr.svg' },
      ],
      deferredPrompt: null,
      hideInstallPromotion: true,
      updateExists: false,
      refreshing: false,
      registration: null,
      snackbar: { active: false, text: '', color: '' },
      installDialog: false,
      options: {},
    }),
    computed: {

      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
      }),
      scrollHeight () {
        return (this.windowHeight - 48) + 'px'
      },
    },
    watch: {
      displayNotification (data) {
        if (data.message) {
          this.toast(data.message, data.color)
        }
      },
    },
    created: function () {
      //  this.loginUser();
      var _this = this

      window.addEventListener('beforeinstallprompt', function (e) {
        // log the platforms provided as options in an install prompt
        /* console.log(e.platforms); // e.g., ["web", "android", "windows"]
         e.userChoice.then(function(choiceResult) {
             console.log(choiceResult.outcome); // either "accepted" or "dismissed"
         }, handleError); */

        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault()
        // Stash the event so it can be triggered later.
        _this.deferredPrompt = e

        setTimeout(function () {
          if (!_this.install_asked) {
            _this.hideInstallPromotion = false
            _this.installDialog = true
          }
          // },100);
        }, 20000)
        // Update UI notify the user they can install the PWA
        // showInstallPromotion();
      })

      // this.registerNotificationManager()
      this.getUser()
      this.getSettings()
      this.setUpFirebase()
    },
    mounted: function () {
      this.items = [
      ]
      try {
        const recaptcha = this.$recaptchaInstance
      } catch (e) {
      }
      this.$nextTick(function () {
        window.addEventListener('resize', this.getWindowWidth)
        window.addEventListener('resize', this.getWindowHeight)

        // Init
        this.getWindowWidth()
        this.getWindowHeight()
      })

      // this.getUser();
      // this.getSettings();

      this.$store.dispatch('init')
      if (this.login) {
        this.getNotifications().then(data => {
          if (data.items.length > 0) {
            this.processServerNotifications(data.items)
            // this.total = data.total;
            // this.setValue({type: 'notifications', value: data.items});
          }
        })
      }

      window.addEventListener(
        'swUpdated', this.showRefreshUI, { once: true },
      )

      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener(
          'controllerchange', () => {
            if (this.refreshing) return
            this.refreshing = true
            window.location.reload()
          },
        )
      }

      this.$store
        .dispatch('checkAuth')
    },

    methods: {
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`
        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      listener () {

      },
      showRefreshUI (e) {
        this.registration = e.detail
        this.updateExists = true
      },
      refreshApp () {
        this.updateExists = false
        if (!this.registration || !this.registration.waiting) { return }
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        window.location.reload()
      },
      processServerNotifications (items) {
        for (var i = 0; i < items.length; i++) {
          var data1 = items[i].data

          if (data1.subject || data1.subject.trim().length > 0) {
            var message = {
              id: data1.id || '',
              title: data1.subject,
              headline: data1.content || '',
              content: data1.content || '',
              action: data1.action || '',
              to: data1.to || '',
              created_at: data1.created_at,
              state: data1.status || 0,
              avatar: data1.avatar,
              isOpened: items[i].read_at !== null,
            }

            // if(data.user_id != this.user.id){
            this.pushItem({
              type: 'notifications',
              field: 'id',
              value: message.id,
              object: message,
            })
          }

          // }
        }
      },
      getSettings () {
        axios.get('/system/settings', {}).then((response) => {
          this.setValue({ type: 'settings', value: response.data })
          window.localStorage.setItem('settings', JSON.stringify(response.data))
          var faqs = response.data.help || []
          for (var i = 0; i < faqs.length; i++) {
            this.pushItem({ type: 'faqs', value: faqs[i].id, field: 'slug', object: faqs[i] })
          }
          //window.localStorage.setItem('planete@faqs', JSON.stringify(this.$store.state.faqs))

          var informations = response.data.information || []
          for (var i = 0; i < informations.length; i++) {
            this.pushItem({ type: 'posts', value: informations[i].id, field: 'slug', object: { ...informations[i], slug: informations[i].key } })
          }
          //window.localStorage.setItem('planete@posts', JSON.stringify(this.$store.state.posts))
        }).catch(error => {
          console.log(error)
        })
      },
      inboxClick () {
        if (this.$store.getters.unreadNotifications.length > 0) {
          axios.get('notifications/read')
        }
        this.notifications.map((item) => {
          item.isOpened = true
        })
        this.not_clicked = false

        /*
           var _this = this;
        setTimeout(function () {
            var i = _this.notifications.length + 1;
            var t = _this.notifications.length + 4;

            for(i;i<t;i++){
                var message = {
                    id: i,
                    title: "title " + i,
                    headline:"headline " + i,
                    content: "content " + i,
                    action: "action " + i,
                    time: "time " + i,
                    isOpened:false,

                };

                    _this.pushItem({type:"notifications",field:"id",value:message.id,object:message});

            }
            _this.not_clicked = true;
        },3000) */
      },
      setUpFirebase () {
        // Retrieve Firebase Messaging object.
        const messaging = window.messaging
        // Add the public key generated from the console here.
        var _this = this
        getToken(messaging, { vapidKey: 'BARLAz1oKY0t6jlJOnyAA18qMrcA6JtkkQRD4pzNLUvGYNscIMhhuZCZ9k7vm7rw7oFl2NKbrBXlq2FxJyUMWS4' }).then((currentToken) => {
          if (currentToken) {
            _this.sendTokenToServer(currentToken)
            _this.updateUIForPushEnabled(currentToken)
          } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.')
            // Show permission UI.
            _this.updateUIForPushPermissionRequired()
            _this.setTokenSentToServer(false)
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
          _this.setTokenSentToServer(false)
        })

        onMessage(messaging, (payload) => {
          console.log('Message received. ', payload)
          var notification = payload.notification
          var data = payload.data

          // chech if there's a notification
          if (notification) {
            var message = {
              id: data.id || '',
              title: data.title || notification.title,
              headline: data.content || notification.body,
              content: data.content || notification.body,
              action: data.action || notification.click_action || '',
              to: data.to || '',
              created_at: data.creatd_at,
              isOpened: false,
              avatar: data.avatar,
            }

            this.pushItem({ type: 'notifications', field: 'id', value: data.id, object: message, unshift: true })
            this.sendMessage(notification)
          } else {

          }
        })
        navigator.serviceWorker.ready.then((registration) => {
          try {
            this.registration = registration
          } catch (e) {
          }
          try {
            messaging.useServiceWorker(registration)
          } catch (e) {
          }
        })
      },
      sendMessage (notification) {
        if (navigator.serviceWorker) {
          navigator.serviceWorker.postMessage({
            type: 'NEW_MESSAGE',
            notification: notification,
            data: notification,
          })

          /* window.dispatchEvent(
             new CustomEvent('newMessage', { detail: registration })
           ); */
        }
      },
      registerNotificationManager () {
        if ('Notification' in window && navigator.serviceWorker) {
          Notification.requestPermission(function (status) {
            console.log('Notification permission status:', status)
          })

          if (Notification.permission === 'granted') {
            navigator.serviceWorker.ready.then(function (reg) {
              /* const options = {
                body: 'Here is a notification body!',
                icon: 'images/example.png',
                vibrate: [100, 50, 100],
                data: {
                  dateOfArrival: Date.now(),
                  primaryKey: 1,
                },
                actions: [
                  {
                    action: 'explore',
                    title: 'Explore this new world',
                    icon: 'images/checkmark.png',
                  },
                  {
                    action: 'close',
                    title: 'Close notification',
                    icon: 'images/xmark.png',
                  },
                ],
              }
              reg.showNotification('Hello world!', options)
              this.sendMessage(options) */
            })
          }
        }
      },

      sendTokenToServer (token) {
        console.log('token', token)
        axios.post('/firebase/token', { token: token }).then((response) => {

        })
      },
      updateUIForPushEnabled (token) {
        // console.log("token", token);
      },

      updateUIForPushPermissionRequired () {

      },

      setTokenSentToServer (sent) {

      },

      promptInstall () {
        // Hide the app provided install promotion
        this.hideInstallPromotion = true
        this.installDialog = false
        this.setValue({ type: 'install_asked', value: true })
        // Show the install prompt
        this.deferredPrompt.prompt()
        var self = this
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            // console.log('User accepted the install prompt');
            self.sendAppInstalled()
          } else {
            // console.log('User dismissed the install prompt');
          }
        })
      },
      sendAppInstalled (token) {
        // console.log("token", token);
        axios.post('/installed', {}).then((response) => {

        })
      },
      getNotifications () {
        // this.loading = true;
        return new Promise((resolve, reject) => {
          // console.log(this.pagination);

          // eslint-disable-next-line no-undef
          axios({
            method: 'get',
            url: '/notifications',
            params: {
              orderBy: 'created_at',
              direction: 'DESC',
              per_page: 10,
            },
            validateStatus: (status) => {
              return true
            },
          }).catch(error => {
            console.log('notifications error', error)
          }).then((response) => {
            if (!response || !response.data || response.data.error || !Array.isArray(response.data.data)) {
              return false
            }

            console.log('notifications data', response)
            const items = response.data.data

            const total = response.data.total
            // this.loading = false;
            resolve({
              items,
              total,
            })
          }).then(() => {
            // this.loading = false;
          })
        })
      },
      getUser: function () {
        // eslint-disable-next-line no-undef
        axios.get(`${process.env.VUE_APP_BASE_URL}/sanctum/csrf-cookie`).then((resp) => {
          // eslint-disable-next-line no-undef
          axios({
            method: 'post',
            url: '/user?fetch=1',
            data: {},
            validateStatus: (status) => {
              return true
            },
          }).catch(error => {

          }).then(async response => {
            console.log('data', response)
            if (response.data.error) {
              this.setValue({
                type: 'login',
                value: false,
              })
              this.setValue({
                type: 'user',
                value: {},
              })
              window.localStorage.removeItem('login')
              window.localStorage.removeItem('userData')
              return false
            }

            if (response.data && response.data.id) {
              this.setValue({
                type: 'login',
                value: true,
              })
              this.setValue({
                type: 'user',
                value: response.data,
              })
              //window.localStorage.setItem('login', JSON.stringify(true))
              if (this.login) {
                this.getNotifications().then(data => {
                  if (data.items.length > 0) {
                    this.processServerNotifications(data.items)
                    // this.total = data.total;
                    // this.setValue({type: 'notifications', value: data.items});
                  }
                })
              }
            } else {
              //this.$store.commit('logout')
            }

            // this is now called!
          })
        })
      },

      logout () {
        localStorage.login = null
        localStorage.removeItem('login')
        window.location.reload()
        router.push('/')
      },
      toast (message, color) {
        this.snackbar.text = message
        this.snackbar.color = color
        this.snackbar.active = true
      },
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      getWindowWidth (event) {
        this.windowWidth = document.documentElement.clientWidth
      },

      getWindowHeight (event) {
        this.windowHeight = document.documentElement.clientHeight
      },
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.getWindowWidth)
      window.removeEventListener('resize', this.getWindowHeight)
    },
  }
</script>

<style lang="scss"></style>
