import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import { i18n } from '../plugins/i18n'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
      baseUrl: process.env.VUE_APP_API || process.env.baseURL || '',
      user: JSON.parse(localStorage.getItem('userData')),
      login: false/* JSON.parse(localStorage.getItem('login') || 'false') */,
      settings: JSON.parse(localStorage.getItem('planete@settings')) || {},
      loading: false,
      logging: false,
      chatDrawer: false,
      chat: {
        title: "Chat",
        image: "image",
        type: "product",
        typeId: null
      },
      loadingProduct: false,
      statuses: {
        orders: {
          RETURN_TRANSIT: -5,
          RETURN_CONFIRMED: -4,
          REWINDED: -3,
          RETURNED: -2,
          CANCEL: -1,
          UNPAID: 0,
          VALIDATED: 1,
          PAID: 2,
          CLOSED: 5,
        },
        orderItem: [
          { status: -4, message: i18n.t('Retour confirmé'), color: 'red white--text' },
          { status: -3, message: i18n.t('Annulé'), color: 'red white--text' },
          { status: -1, message: i18n.t('Produit retournée'), color: 'red white--text' },
          { status: 0, message: i18n.t('En cours'), color: 'grey lighten-2' },
          { status: 1, message: i18n.t('En attente de validation de colis par les vendeurs'), color: 'info white--text' },
          { status: 4, message: i18n.t('Validé'), color: 'success white--text' },
          { status: 5, message: i18n.t('Remboursement initier'), color: 'red white--text' },
          { status: 6, message: i18n.t('Remboursement confirmé'), color: 'red white--text' },
          { status: 7, message: i18n.t('cloture'), color: 'red white--text' },
      ],
        packages: [
          { status: -5, message: i18n.t('Retour en Transit'), color: 'red white--text' },
          { status: -4, message: i18n.t('Retour confirmé avec contentieux'), color: 'red white--text' },
          { status: -3, message: i18n.t('Annulé'), color: 'red white--text' },
          { status: -2, message: i18n.t('En Litige'), color: 'red white--text' },
          { status: -1, message: i18n.t('Renvoyé'), color: 'red white--text' },
          { status: 0, message: i18n.t('Impayé'), color: 'grey lighten-2' },
          { status: 1, message: i18n.t('Validation'), color: 'info white--text' },
          { status: 2, message: i18n.t('Validé'), color: 'success white--text' },
          { status: 3, message: i18n.t('En cours'), color: 'success white--text' },
          { status: 4, message: i18n.t('Colis validé/Livré'), color: 'green white--text' },
          { status: 5, message: i18n.t('Litige résolu'), color: 'info white--text' },
          { status: 7, message: i18n.t('cloture'), color: 'red white--text' },
      ],
        packageStatus: {
          notPaid: 0,
          paid: 1,
          validated: 2,
          processing: 3,
          delivered: 4,
          issueSolved: 5,
          closed: 7,
          returned: -1,
          issue: -2,
          cancelled: -3,
          returnConfirmed: -4,
          returnTransit: -5,
        },
        order: [
          { status: 5, message: i18n.t('Colis validés'), color: 'success' },
          { status: 4, message: i18n.t('Validé'), color: 'success' },
          { status: 4, message: i18n.t('Validé'), color: 'success' },
          { status: 1, message: i18n.t('En attente de validation de colis par les vendeurs'), color: 'info' },
          { status: 0, message: i18n.t('Incomplet'), color: 'warning' },
          /* { status: null, message: i18n.t('Incomplet'), color: 'warning' }, */
          { status: -1, message: i18n.t('Commande annulée'), color: 'red' },
          { status: -1, message: i18n.t('Commande annulée'), color: 'red' },
      ],
        offers: [
          { status: 1, message: i18n.t('Accepté'), color: 'info' },
          { status: 0, message: i18n.t('Incomplet'), color: 'warning' },
          { status: -1, message: i18n.t('Indisponible'), color: 'red' },
          { status: null, message: i18n.t('Incomplet'), color: 'warning' },
      ],
        products: [
          { status: 1, message: i18n.t('Actif Boutique'), color: 'success' },
          { status: 0, message: i18n.t('Inactif Boutique'), color: 'warning' },
      ],
        products_admin: [
          { status: 1, message: i18n.t('Actif Admin'), color: 'success' },
          { status: 0, message: i18n.t('Inactif Admin'), color: 'warning' },
      ],
      },
      banners: JSON.parse(localStorage.getItem('planete@banners')) || [],
      categories: JSON.parse(localStorage.getItem('planete@categories')) || [],
      topCategories: JSON.parse(localStorage.getItem('planete@topCategories')) || [],
      shops: JSON.parse(localStorage.getItem('planete@shops')) || [],
      topShops: [],
      promoShops: [],
      countries: [],
      homeSliders: JSON.parse(localStorage.getItem('planete@homeSliders')) || [],
      posts: JSON.parse(localStorage.getItem('planete@posts')) || [],
      faqs: JSON.parse(localStorage.getItem('planete@faqs')) || [],
      breadcrumbs: [
      ],
      salutations: [
        i18n.t('M.'),
        i18n.t('MME'),
        i18n.t('MLE'),
        i18n.t('MLLE'),
      ],
      notifications: [],
      products: [],
      orders: [],
        products1: [],
        moreproducts: [

        ],
        newProducts: [

        ],
        category: [
          {
            checkbox: false,
            title: 'Watch',

          },
          {
            checkbox: false,
            title: 'Mobile',

          },
          {
            checkbox: false,
            title: 'Sneakers',

          },
          {
            checkbox: false,
            title: 'Accessories',

          },
          {
            checkbox: false,
            title: 'Electronics',

          },
          {
            checkbox: false,
            title: 'Car',

          },
          {
            checkbox: false,
            title: 'Grocery',

          },
        ],
        ratings: [
          {
            id: 11,
            rates: 5,
          },
          {
            id: 12,
            rates: 4,
          },
          {
            id: 13,
            rates: 3,
          },
          {
            id: 14,
            rates: 2,
          },
          {
            id: 15,
            rates: 1,
          },
        ],
        flashSales: [],
      cartProducts: JSON.parse(localStorage.getItem('planete@cartProducts')) || [],
        cart: JSON.parse(localStorage.getItem('planete@cart')) || { products: [] },
    },
    getters: {
        getPosts: state => state.posts,
        getFaqs: state => state.faqs,
        getFlashSales: state => state.flashSales,
        getProducts: state => state.products,
        getCartProducts: state => state.cart.products || [],
        getCategory: state => state.category,
        getRatings: state => state.ratings,
        getChat: state => state.chat,

      isLoggedIn: (state) => {
        return state.login
      },
      getUser: (state) => {
        return state.user
      },
      unreadNotifications: (state) => {
        return state.notifications.filter((item) => !item.isOpened)
      },

    },
    actions: {
      addCart ({ commit }, data) {
        commit('ADD_CART', data)
        commit('updateCart', data)
      },
      removeCart ({ commit }, data) {
        commit('REMOVE_CART', data)
      },
      checkAuth (context) {
        return context.commit('loadUser')
      },
      init ({ commit, state, dispatch }) {
        // commit('loadUser')
        // dispatch('fetchFlashSales')
        dispatch('fetchCountries')
        dispatch('fetchShops')
        dispatch('fetchTopShops')
        dispatch('fetchCategories')
        dispatch('fetchTopCategories')
        dispatch('fetchCart')
      },
      processData (product) {
        return {
          ...product,
          /* img: require('@/assets/images/products/flash-1.png'),
          image: require('@/assets/images/products/flash-1.png'),
          thumb: require('@/assets/images/products/flash-1.png'), */
          title: product.name,
          categoryName: 'Sneakers',
          amount: product.price_with_tax,
          original_price: product.price_with_tax,
          promo: product.promo,
          qty: 0,
          stock_physic: product.stock_physic,
          stock_available: product.stock_available,
          stock_reserved: product.stock_reserved,
          test: this.shortHtml('', 10),
        }
      },
      setUser: ({ state }, userData) => {
        // localStorage.setItem('userData', JSON.stringify(userData))
        // localStorage.setItem('login', true)
      },
      fetchShops ({ state }, options) {
        axios
          .get('/shops', { params: options })
          .then(response => {
            if (response.data && response.data.data) {
              state.shops = response.data.data
              /* localStorage.setItem('planete@shops', JSON.stringify(response.data.data)) */
            }
          })
          .catch(error => console.log(error))
      },
      fetchTopShops ({ state }, options) {
        axios
          .get('/shops?&top=1', { params: options })
          .then(response => {
            if (response.data && response.data.data) {
              state.topShops = response.data.data
              // localStorage.setItem('planete@topShops', JSON.stringify(response.data.data))
            }
          })
          .catch(error => console.log(error))
      },

      fetchCountries ({ state }, options) {
        axios
          .get('/countries', { params: options })
          .then(response => {
            if (response.data) {
              state.countries = response.data
            }
          })
          .catch(error => console.log(error))
      },
      fetchCart ({ state }, options) {
        if (state.cart.id) {
          axios
            .get('/cart/' + state.cart.id, { params: options })
            .then(response => {
              if (response.data) {
                var c = response.data
                var items = c.items || []
                c.products = items.map((e) => {
                  return {
                    ...e,
                    id: e.product_id,
                    price_without_tax: e.prix_ht || (e.product ? e.product.price_without_tax : 0),
                    name: e.name || (e.product ? e.product.name : ''),
                    image: e.image || (e.product ? e.product.image : null),
                    quantity: e.product ? e.product.quantity : 1,
                  }
                })
                state.cart = c
                localStorage.setItem('planete@cart', JSON.stringify(state.cart))
              } else {
                state.cart = { products: [] }
                localStorage.removeItem('planete@cart')
              }
            })
            .catch(error => console.log(error))
        }
      },
      fetchFlashSales ({ state }, options) {
        axios
          .get('/flashsales', { params: options })
          .then(response => {
            if (response.data) {
              if (response.data.data) {
                state.flashSales = response.data.data.map((ele) => {
                  return {
                    ...ele,
                    title: ele.name,
                    categoryName: 'Sneakers',
                    amount: ele.price_with_tax,
                    original_price: ele.price_with_tax,
                    promo: ele.promo,
                    qty: 0,
                  }
                })
              } else {
                state.flashSales = response.data.map((ele) => {
                  return {
                    ...ele,
                    title: ele.name,
                    categoryName: 'Sneakers',
                    amount: ele.price_with_tax,
                    original_price: ele.price_with_tax,
                    promo: ele.promo,
                    qty: 0,
                  }
                })
              }
              for (var i = 0; i < state.flashSales.length; i++) {
                commit('pushItem', { type: 'products', field: 'id', value: state.flashSales[i].id, object: state.flashSales[i] })
              }
            }
          })
          .catch(error => console.log(error))
      },
      fetchProduct ({ state }, slug) {
        state.loadingProduct = true
        axios
          .get(`/products/${slug}`)
          .then(response => {
            if (response.data && response.data.id) {
                var ele = response.data
                var product = {
                  ...ele,
                  /* img: require('@/assets/images/products/flash-1.png'),
                  image: require('@/assets/images/products/flash-1.png'),
                  thumb: require('@/assets/images/products/flash-1.png'), */
                  title: ele.name,
                  categoryName: 'Sneakers',
                  amount: ele.price_with_tax,
                  original_price: ele.price_with_tax,
                  promo: ele.promo,
                  qty: 0,
                  user: {
                    ...ele.user,
                    avatar: 'http://localhost:8080/img/ralph.c013bdf1.png',
                  },
                }
                commit('pushItem', { type: 'products', field: 'id', value: product.id, object: product })
            }
          })
          .catch(error => console.log(error)).then(() => {
            state.loadingProduct = false
        })
      },
      fetchCategories ({ state }, options) {
        axios
          .get('/categories', { params: options })
          .then(response => {
            console.log(response.data)
            if (response.data) {
              state.categories = response.data
              // localStorage.setItem('planete@categories', JSON.stringify(response.data))
            }
          })
          .catch(error => console.log(error))
      },
      fetchTopCategories ({ state }, options) {
        axios
          .get('/categories?&top=1', { params: options })
          .then(response => {
            console.log(response.data)
            if (response.data) {
              state.topCategories = response.data
              // localStorage.setItem('planete@topCategories', JSON.stringify(response.data))
            }
          })
          .catch(error => console.log(error))
      },
    },
    mutations: {
      ADD_CART (state, data) {
        /* if (data.quantity <= data.qty) {
          return false
        } */
        let productExist = false

        state.cart.products = state.cart.products || []
        state.cart.products = state.cart.products.map(product => {
          if (product.id === data.id) {
            productExist = true
            product.quantity = data.quantity || 1
            product.qty = data.qty || 0
            /* product.qty += 1 */
          }
          return product
        })

        if (!productExist) {
         /* data.quantity = data.quantity || 1
          data.qty = data.qty || 1 */
          /* data.qty += 1 */
          state.cart.products.push(data)
        }
        localStorage.setItem('planete@cart', JSON.stringify({ id: state.cart.id, products: state.cart.products.map((ele) => { return { id: ele.id, qty: ele.qty, price_without_tax: ele.price_without_tax, total: ele.total, name: ele.name, quantity: ele.quantity } }) }))
      },
      REMOVE_CART (state, data) {
        if (data.qty > 0) {
          data.qty -= 1
        } else {
          data.qty = 0
        }
        if (data.qty === 0) {
          state.cart.products = state.cart.products.filter(product => product.id !== data.id)
          axios.get(`/cart/remove/${data.id}`, { params: { cart_id: state.cart.id } }).then(() => {})
        } else {
          store.commit('updateCart', data)
        }
        if (state.cart.products && state.cart.products.length === 0) {
          // state.cart.id = null
          localStorage.removeItem('planete@cart')
        } else {
          localStorage.setItem('planete@cart', JSON.stringify({ id: state.cart.id, products: state.cart.products.map((ele) => { return { id: ele.id, qty: ele.qty, price_without_tax: ele.price_without_tax, total: ele.total, name: ele.name, quantity: ele.quantity, image: ele.image } }) }))
        }
      },
      updateCart (state, product) {
        axios.post(`/cart/add/${product.id}`, { quantity: product.qty, update: true, cart_id: state.cart.id })
          .then((response) => {
            // if (response.data && response.data.cart_id) {
              state.cart.id = response.data.cart_id
              localStorage.setItem('planete@cart', JSON.stringify({ id: response.data.cart_id, products: state.cart.products.map((ele) => { return { id: ele.product_id, qty: ele.qty, price_without_tax: ele.price_without_tax, total: ele.total, name: ele.name, quantity: ele.quantity, image: ele.image } }) }))
            // }
          }).then(() => {

        })
      },
      updateChat (state, payload) {
        state.chat = payload
        state.chatDrawer = true
      },
      pushItem (state, payload) {
        // console.log(payload);
        // var index = this.getIndex(state[payload.items],payload.field,payload.value );

        for (var i = 0; i < state[payload.type].length; i++) {
          if (payload.value == state[payload.type][i][payload.field]) {
            Vue.set(state[payload.type], i, payload.object)
            return true
          }
        }
        if (payload.unshift) {
          state[payload.type].unshift(payload.object)
        } else {
          state[payload.type].push(payload.object)
        }

        // console.log(state[payload.type]);
      },
      removeItem (state, payload) {
        for (var i = 0; i < state[payload.type].length; i++) {
          if (payload.value === state[payload.type][i][payload.field]) {
            state[payload.type].splice(i, 1)
            return true
          }
        }
      },

      setValue (state, payload) {
        console.log(payload.type, payload.value)
        state[payload.type] = payload.value
      },
      logout (state) {
        localStorage.login = null
        localStorage.removeItem('login')
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        state.login = false
        state.user = null
        axios.get('/logout').then((response) => {
          router.push('/')
        })

        // router.push('/login')
      },
      loadUser (state, commit, dispatch) {
        // eslint-disable-next-line no-undef
        return axios({
          method: 'post',
          url: '/user',
          data: {},
          validateStatus: (status) => {
            console.log(status)
            return true
          },
        }).catch(error => {
          state.login = true
          return true
        }).then(response => {
          if (response && response.data && response.data.error) {
            return false
          }
          if (response && response.data && response.data.login) {
            return
          }
          if (response && response.data) {
            state.login = true
            state.user = response.data || {}
            localStorage.setItem('userData', JSON.stringify(state.user))
            console.log('preview', state.user)
          }

          return true
          // this is now called!
        })
      },
    },

})
